// @ts-ignore
import * as forge from 'node-forge'
import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';

import {v4 as uuidv4} from 'uuid';

import {Request, RequestObject, AuthenticationByApplication, Ping, AddMessage, GetServerSettings} from './request'
import {AddedMessage, Response, ResponseObject, StatusMessage, ServerSettings, Theme} from './response'

import {Observable} from 'rxjs';

import {
  ConversationDisplay, DataEncryptedByMetaDataKey,
  DataEncryptedBySessionKey,
  FileDownloadDisplay,
  FileEncrypted,
  FileParams,
  Message,
  User
} from "./model";


import {Encryption, Timer, TimerFactory} from "./encryption";

import {Storage} from "./storage"


import {QueueingSubject} from 'queueing-subject'
import {Subscription} from 'rxjs'
import {last, share, switchMap} from 'rxjs/operators'
import makeWebSocketObservable, {
  GetWebSocketResponses,
  normalClosureMessage,
} from 'rxjs-websockets'
import {HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpParams, HttpRequest} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError, tap, map} from 'rxjs/operators';


declare type WebSocketPayload = string | ArrayBuffer | Blob;
export declare type ResponseFunction = (responseObject: ResponseObject) => void;

@Injectable({
  providedIn: 'root'
})
export class MessangerConnectionService {
  timerFactory = new TimerFactory();

  user: User = new User("", "", "", new Date(), new Date(), "", "");
  appUuid: any = null;
  userId: any = null;
  userIdOpen: any = null;
  nickOpen: any = null;
  authorized: boolean = false;

  supportId: string = "";
  adminId: string = "";
  bigBrother: boolean = false;

  theme: Theme = new Theme()

  encryption: any = null;
  storage: any = null;

  private responseHandlers: Map<Number, [ResponseFunction, Timer]> = new Map();
  private requestCounter: number = 0;
  private messages$: any;
  private input$ = new QueueingSubject<string>();
  private notificationHandler: any;

  hostname:string = "";
  port:string = "";
  protocol:string = "";



  constructor(@Inject(LOCAL_STORAGE) private storageService: StorageService, private http: HttpClient) {

    this.hostname = window.location.hostname
    this.port = window.location.port
    this.protocol = window.location.protocol.replace(':','')
    this.init(storageService);
  }

  init(storageService: StorageService) {
    this.storage = new Storage(storageService);
    let websocketProtocol = "ws";
    if(this.protocol == "https") {
      websocketProtocol = "wss"
    }
    const socket$ = makeWebSocketObservable(`${websocketProtocol}://${this.hostname}:${this.port}/socket`);
    ;
    // const socket$ = makeWebSocketObservable(`${websocketProtocol}://${this.hostname}:${this.port}/socket`);

    this.messages$ = socket$.pipe(
      switchMap((getResponses: GetWebSocketResponses) => {
        console.log('websocket opened');
        let websocket = this;
        setTimeout(() => websocket.ping(), 1000);
        let getRequest = new GetServerSettings();
        this.sendRequest(getRequest, (resp) => {
          let r: ServerSettings = resp as ServerSettings
          this.supportId = r.supportId;
          this.adminId = r.adminId;
          this.bigBrother = r.bigBrother;
          this.theme = r.theme;
        })

        return getResponses(this.input$)
      }),
      share(),
    );

    this.subscribe(
      (message) => {
        let result = Response.parseJson(message);
        let responseObject = result[0];
        let responseId = result[1];
        let responseHandler = this.responseHandlers.get(responseId);
        if (responseHandler != null) {
          try {
            responseHandler[1].stop();
            responseHandler[0](responseObject);
          } catch (e) {
            // console.log(e);
          }
        } else {
          if (this.notificationHandler != null) {
            try {
              this.notificationHandler(responseObject);
            } catch (e) {
              console.log(e);
            }
          }
        }

      },
      (error) => {
        console.log("websocket error");
        setTimeout(()=>{
          window.location.href ="/";
        }, 5000)
      }, () => {
        console.log("websocket closed");
        setTimeout(()=>{
          window.location.href ="/";
        }, 5000)
      }
    );

    this.appUuid = this.storage.getAppUuid();
    this.userId = this.storage.getUserId();
    this.userIdOpen = this.storage.getUserIdOpen();

    console.log("appUuid:");
    console.log(this.appUuid);
    console.log("userId:");
    console.log(this.userId);
  }

  authorizeApp(next: () => void, error: (err: string) => void) {

    if (!this.authorized) {

      let requestAuthenticationByApplication = new AuthenticationByApplication(this.appUuid, this.userId);
      this.sendRequest(requestAuthenticationByApplication,
        (responseAuthentication) => {
          if ((responseAuthentication as StatusMessage).status == 'ok') {
            this.authorized = true;
            next();
          } else {
            console.log("AuthenticationByApplication err");
            error((responseAuthentication as StatusMessage).description);
          }
        }
      );
    } else {
      next();
    }

  }


  sendRequest(requestObject: RequestObject, responseHandler: ResponseFunction) {
    // let timer =this.timerFactory.start("sendRequest", requestObject.constructor.name + " " +JSON.stringify(requestObject));
    let timer =this.timerFactory.start("sendRequest_"+requestObject.constructor.name);
    this.requestCounter++;
    this.responseHandlers.set(this.requestCounter, [responseHandler,timer]);
    this.send(Request.toJson(requestObject, this.requestCounter));
  }

  sendRequestObservable(requestObject: RequestObject) {
    const observable: Observable<ResponseObject> = new Observable(observer => {
      this.sendRequest(requestObject, (resp) => {
        observer.next(resp);
        observer.complete();
      })
    })
    return observable;
  }

  onNotification(notificationHandler: ResponseFunction) {
    this.notificationHandler = notificationHandler;
  }

  private send(msg: string) {
    this.input$.next(msg);
  }

  private subscribe(
    onMessage: (message: string) => void,
    onError: (error: Error) => void,
    onClose: () => void
  ): Subscription {

    return this.messages$.subscribe(onMessage, onError, onClose);

  }

  clear() {
    this.storage.clear();

    this.user = new User("", "", "", new Date(), new Date(), "", "");
    this.appUuid = null;
    this.userId = null;
    this.authorized = false;

    this.encryption = null;
    this.storage = null;
    this.input$ = new QueueingSubject<string>();
    this.init(this.storageService);
  }

  ping() {
    let websocket = this;
    let pingRequest = new Ping(new Date());
    console.log("ping");
    this.sendRequest(pingRequest, (statusResponse) => {
      console.log("pong");
      setTimeout(() => websocket.ping(), 30000);
    })
  }


  sendMessage(message: string, conversationUuid: string, sessionKeyUuid: string, next: (responseAddedMessage: AddedMessage) => void) {

    this.encryption.encryptMessage(message, conversationUuid,
      sessionKeyUuid, this.userId, (encryptedMessage: Message) => {
        try {
          let ivBase64 = encryptedMessage.ivBase64;
          let bodyEncryptedBase64 = encryptedMessage.bodyEncryptedBase64;
          let messageUuid = uuidv4();
          let requestAddMessage = new AddMessage(messageUuid,conversationUuid, sessionKeyUuid,
            ivBase64, bodyEncryptedBase64, false);
          this.sendRequest(requestAddMessage,
            (responseAddedMessage) => {
              next(responseAddedMessage as AddedMessage);
            }
          );
        } catch (e) {
          console.log(e);
          next({} as AddedMessage);
        }

      });
  }


  downloadFile(filename: string) {
    return this.http.get(filename, {responseType: 'arraybuffer'})
      .pipe(
        tap(
          data => {
            // console.log(filename + " " + data)
          },
          error => {
            // console.log(filename + " " + error)
          }
        )
      );
  }


  private b64toBlob(b64Data: any, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  uploadFiles(files: File[], params: FileParams[],message: string, conversationUuid: string, sessionKeyUuid: string, next: () => void) {

    this.encryption.encryptMessage(message, conversationUuid,
      sessionKeyUuid, this.userId, (encryptedMessage: Message) => {
        let fileDownloadDisplays: FileDownloadDisplay[] =[];
        this.encryptFiles(files, params ,fileDownloadDisplays, conversationUuid, sessionKeyUuid, encryptedMessage, () => {
          this.uploadFileImpl(conversationUuid, fileDownloadDisplays ,encryptedMessage,  next);
        });
      });
  }

  private encryptFiles(files: File[], params: FileParams[], fileDownloadDisplays:FileDownloadDisplay[], conversationUuid: any, sessionKeyUuid: any, encryptedMessage: Message, next:()=>void) {
    let file = files.pop();
    let param = params.pop();
    this.encryption.encryptFile(file, param,forge.util.decode64(encryptedMessage.ivBase64), conversationUuid, sessionKeyUuid, (fileDownloadDisplay: FileDownloadDisplay) => {
      fileDownloadDisplays.push(fileDownloadDisplay);
      if(files.length == 0) {
        next();
      } else {
        this.encryptFiles(files, params,fileDownloadDisplays, conversationUuid, sessionKeyUuid, encryptedMessage, next);
      }
    });

  }
  // private arrayBufferBinary(buffer) {
  //   var binary = '';
  //   var bytes = new Uint8Array(buffer);
  //   var len = bytes.byteLength;
  //   for (var i = 0; i < len; i++) {
  //     binary += String.fromCharCode(bytes[i]);
  //   }
  //   return binary;
  // }

  uploadAvatar(f: File, next: () => void) {
    let sessionKey = this.encryption.generateSessionKey();
    let iv = this.encryption.generateIV();
    this.encryption.encryptAvatarByMetaDataKey(f.slice(), iv, (fileEncryptedByPublicKey:DataEncryptedByMetaDataKey) => {
      let jsonFile = JSON.stringify(fileEncryptedByPublicKey);

      let formData = new FormData();
      formData.append('files', new File([jsonFile],'avatar.jpeg'), "avatar.jpeg");
      formData.append("userId", this.userId);
      formData.append("applicationUuid", this.appUuid);
      const req = new HttpRequest('POST', `${this.protocol}://${this.hostname}:${this.port}/uploadAvatar`, formData, {
        reportProgress: true
      });

      let file = new File([], "", undefined);

      this.http.request(req).pipe(
        map(event => {
          this.getEventMessage(event, file);
          if(event.type == HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * event.loaded / (event.total as number));
            if(percentDone == 100) {
              next();
            }
          }
          return "uploading...";
        }),
        tap(message => this.showProgress(message)),
        last(), // return last (completed) message to caller
        catchError(this.handleError(file))
      ).subscribe(result => {
        console.log("subscribe");
        next();
      });



    });
  }

  uploadConversationAvatar(f: File, conversationUuid: string, next: () => void) {

    let formData = new FormData();
    formData.append('files', f, "avatar.jpeg");
    formData.append("userId", this.userId);
    formData.append("applicationUuid", this.appUuid);
    formData.append("conversationUuid", conversationUuid);
    const req = new HttpRequest('POST', `${this.protocol}://${this.hostname}:${this.port}/uploadConversationAvatar`, formData, {
      reportProgress: true
    });

    let file = new File([], "", undefined);

    this.http.request(req).pipe(
      map(event => {
        this.getEventMessage(event, file);
        if(event.type == HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * event.loaded / (event.total as number));
          if(percentDone == 100) {
            next();
          }
        }
        return "uploading...";
      }),
      tap(message => this.showProgress(message)),
      last(), // return last (completed) message to caller
      catchError(this.handleError(file))
    ).subscribe(result => {
      console.log("subscribe");
      next();
    });

  }

  uploadAvatarToConversation(f: File, conversationUuid: string, next: () => void) {

    let formData = new FormData();
    formData.append('files', f, "avatar.jpeg");
    formData.append("userId", this.userId);
    formData.append("applicationUuid", this.appUuid);
    formData.append("conversationUuid", conversationUuid);
    const req = new HttpRequest('POST', `${this.protocol}://${this.hostname}:${this.port}/uploadAvatarToConversation`, formData, {
      reportProgress: true
    });

    let file = new File([], "", undefined);

    this.http.request(req).pipe(
      map(event => {
        this.getEventMessage(event, file);
        if(event.type == HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * event.loaded / (event.total as number));
          if(percentDone == 100) {
            next();
          }
        }
        return "uploading...";
      }),
      tap(message => this.showProgress(message)),
      last(), // return last (completed) message to caller
      catchError(this.handleError(file))
    ).subscribe(result => {
      console.log("subscribe");
      next();
    });

  }

  private uploadFileImpl(conversationUuid: string, files:FileDownloadDisplay[],encryptedMessage: Message, next: () => void) {
    let formData = new FormData();
    files.forEach(fileDownloadDisplay => {
      const blob = this.b64toBlob(fileDownloadDisplay.bodyEncrypted, 'image/jpeg');
      formData.append('files', blob, fileDownloadDisplay.fileNameEncrypted);
    });
    formData.append("userId", this.userId);
    formData.append("applicationUuid", this.appUuid);
    formData.append("messageUuid", uuidv4());
    formData.append("conversationUuid", conversationUuid);
    formData.append("messageIv", encryptedMessage.ivBase64);
    formData.append("sessionKeyUuid", encryptedMessage.sessionKeyUuid);
    formData.append("messageBodyEncrypted", encryptedMessage.bodyEncryptedBase64);
    formData.append("replyMessageUuid", "");
    formData.append("filesInfo", JSON.stringify(files.map(f => new FileEncrypted(f.fileNameEncrypted,[], f.params))));
      const req = new HttpRequest('POST', `${this.protocol}://${this.hostname}:${this.port}/upload`, formData, {
      reportProgress: true
    });

    let file = new File([], "", undefined);

    this.http.request(req).pipe(
      map(event => {
        this.getEventMessage(event, file);
        if(event.type == HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * event.loaded / (event.total as number));
          if(percentDone == 100) {
            next();
          }
        }
        return "uploading...";
      }),
      tap(message => this.showProgress(message)),
      last(), // return last (completed) message to caller
      catchError(this.handleError(file))
    ).subscribe(result => {
      console.log("subscribe");
      next();
    });
  }

  private getEventMessage(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        return `Uploading file "${file.name}" of size ${file.size}.`;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round(100 * event.loaded / (event.total as number));
        return `File "${file.name}" is ${percentDone}% uploaded.`;

      case HttpEventType.Response:
        return `File "${file.name}" was completely uploaded!`;

      default:
        return `File "${file.name}" surprising upload event: ${event.type}.`;
    }
  }

  private showProgress(message: string) {
      //console.log(message);
  }


  private handleError(file: File) {
    return function (p1: any, p2: Observable<unknown>) {
      return [];
    };
  }

  formatPhone(str: String) {
    let start = str.substring(0,2);
    let code = str.substring(2,5);
    let part1 = str.substring(5,100);

    let result = start + " " + code + " " + part1

    return result;
  }

  sha256Base64(str: string) {
    var md = forge.md.sha256.create();
    md.update(str);
    return forge.util.encode64(md.digest().getBytes())
  }

  downloadAvatarForConversation(conversation: ConversationDisplay, userId: string, next: (avatar:any) => void) {
    let file$ = null;
    if(userId != "") {
      file$ = this.downloadFile("/download/avatars/" + conversation.uuid.replace(/\//g,"_")+ userId.replace(/\//g,"_") + ".jpeg" + "?rnd=" + Math.random());
    } else {
      file$ = this.downloadFile("/download/avatars/" + conversation.uuid.replace(/\//g,"_") + ".jpeg" + "?rnd=" + Math.random());
    }
    file$.subscribe((results: ArrayBuffer) => {

      try {
        let dataEncryptedBySessionKey: DataEncryptedBySessionKey = DataEncryptedBySessionKey.initBy(this.arrayBufferToString(results));
        this.encryption.decryptAvatarForConversation(dataEncryptedBySessionKey, conversation, (avatar: any) => {
          if(avatar != null) {
            next ("data:image/jpeg;base64," + avatar)
          } else {
            next ("/download/avatars/user-profile.jpeg" + "?rnd=" + Math.random())
          }
        } );

      } catch (e) {
        next("/download/avatars/user-profile.jpeg" + "?rnd=" + Math.random());
      }

    }, error => {
      next("/download/avatars/user-profile.jpeg" + "?rnd=" + Math.random());

    });

  }

  downloadAvatar(next: (avatar: any) => void) {
    if (this.userId == null || this.userId == undefined) {
      return;
    }
    let file$ = this.downloadFile("/download/avatars/" + this.userId.replace(/\//g,"_") + ".jpeg" + "?rnd=" + Math.random());
    file$.subscribe((results: ArrayBuffer) => {

      try {
        next("data:image/jpeg;base64," +  this.encryption.decryptAvatarByMetaDataKey(this.arrayBufferToString(results)));

      } catch (e) {
        next("/download/avatars/user-profile.jpeg" + "?rnd=" + Math.random());
      }

    }, error => {
      next("/download/avatars/user-profile.jpeg" + "?rnd=" + Math.random());

    });
  }
  downloadAvatarImpl(next: (avatar: any) => void) {
    let file$ = this.downloadFile("/download/avatars/" + this.userId.replace(/\//g,"_") + ".jpeg" + "?rnd=" + Math.random());
    file$.subscribe((results: ArrayBuffer) => {

      next(this.encryption.decryptAvatarByMetaDataKeyImpl(this.arrayBufferToString(results)));

    }, error => {
      next(null);

    });
  }


  arrayBufferToString(buffer: any) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return binary;
  }
}
