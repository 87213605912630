<mat-sidenav-container>
  <mat-sidenav>
    Sidenav content
  </mat-sidenav>

  <mat-sidenav-content>

    <app-nav></app-nav>

    <div [hidden]="!hideSmsCode">
      <mat-card style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.43);">
        <div style="padding: 10px 5px 5px 5px;">
          <span
            style="padding: 0px 0px 0px 20px;font-family: Roboto;font-size: 20px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: normal;letter-spacing: normal;color: #4a4a4a;">Delete account</span>

          <form [formGroup]="phoneNumber">
            <mat-form-field appearance="standard" style="width:80px;padding: 20px;">
              <mat-label>Country code</mat-label>
              <mat-select [formControl]="code" style="width:100px;text-align: center;">
                <mat-option value="+{{code.num}}" *ngFor="let code of codes">{{code.iso}} +{{code.num}}</mat-option>
              </mat-select>
              <!--              <mat-hint align="center">Select country please</mat-hint>-->

            </mat-form-field>
            <mat-form-field appearance="standard" style="width:120px;padding: 20px 0px 0px 20px">
              <mat-label>Phone number</mat-label>
              <input matInput #input minlength="7" [formControl]="userIdOpen" (keydown)="registerAppKeys($event)"
                     [autofocus]="hideSmsCode">
              <!--              <mat-hint align="center">Min 10 numbers</mat-hint>-->
            </mat-form-field>

          </form>
          <div style="text-align: right;">
            <button (click)="delete()" [disabled]="!userIdOpen.valid" mat-flat-button mat-button
                    style="color:{{con.theme.buttonText}}; background-color: {{con.theme.buttonBackground}}; width: 104px;align-items: flex-end;">
              Next >
            </button>
          </div>
        </div>
      </mat-card>
    </div>


    <div [hidden]="hideSmsCode">


      <mat-card style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.43); width: 380px;">

        <div style="padding: 10px 5px 5px 5px;">

          <span
            style="padding: 0px 0px 0px 20px;font-family: Roboto;font-size: 20px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: normal;letter-spacing: normal;color: #4a4a4a;">
            SMS send to<br> {{formatPhone(code.value + userIdOpen.value)}}
          </span>

          <form [formGroup]="smsConfirmation">
            <mat-form-field appearance="standard" style="padding: 20px 0px 0px 20px; width: 280px;">
              <mat-label>SMS code</mat-label>
              <input matInput #input (keydown)="confirmAppKeys($event)" [autofocus]="focusSmsCode"
                     [formControl]="smsCode">
              <mat-error *ngIf="!smsCodeErrorHide">
                {{errStr}}
              </mat-error>
            </mat-form-field>
          </form>
          <div style="text-align: right;">
            <a (click)="back()" mat-button style="width: 104px;align-items: flex-end; margin-right: 10px">< Back </a>
            <button (click)="deleteConfirm()" [disabled]="!smsCode.valid" mat-flat-button mat-button color="primary"
                    style="color:{{con.theme.buttonText}}; background-color: {{con.theme.buttonBackground}}; width: 104px;align-items: flex-end; ">
              Next >
            </button>
          </div>

        </div>

      </mat-card>


    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
